import { likeRegExp } from '@/offline/database'

export default {
  _selectSyncBase (Vue) {
    const tables = Vue.$offline.db.tables
    const syncRes = Vue.$offline.sync
    return Vue.$offline.db
      .select()
      .from(tables.sync)
      .innerJoin(syncRes.dummyTable, syncRes.pk.eq(syncRes.dummyPk))
      .orderBy(tables.sync.timestamp, Vue.$offline.db.order.DESC)
  },
  async selectSync (Vue, filter, search, sorter, page, idusuario) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.sync.method.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        ...whereSearch,
        ...whereFilter,
        Vue.$offline.db.op.and(
          tables.sync.pending.eq(true),
          Vue.$offline.db.op.or(
            tables.sync.idusuario.eq(idusuario),
            // idusuario -> no debería ser nullable pero el upgrade de la BD se podría ejecutar
            // sin un usuario logado
            tables.sync.idusuario.isNull(),
          )
        )
      )
    )
    return [
      await this._selectSyncBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  selectSyncRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectSyncBase(Vue)
      .where(tables.sync.idsync.in(pks))
      .exec()
  },
  async delete (Vue, idsync) {
    const tables = Vue.$offline.db.tables
    await Vue.$offline.sync.delete({
      where: tables.sync.idsync.eq(idsync)
    })
  },
}
